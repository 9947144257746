//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_RESPONSE_STATE, ENUM_SPPA_STATUS } from "../constants/enum.constant";
import { NumberConstant } from "../constants/number.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { ConvertByteForPreview } from "../functions/pipes/converter.pipe";
import { BaseModel } from "./bases/base.model";
import { BeneficiariesMyMotorcycleModel } from "./beneficiariesmymotorcycle.model";
import { ProductGeneralModel } from "./productgeneral.model";
import { ProductMyMotorcycleGalleryModel } from "./productmymotorcyclegallery.model";
import { ResponseModel } from "./response.model";
import { SupportingDocumentModel } from "./supportingdocument.model";

//#endregion


//#region CLASS

export class ProductMyMotorcycleModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for product asmik my house.
		Author: Eka Saputra.
		Created on : Thursday, 02 June 2022. 				Updated on : Thursday, 02 June 2022.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductGeneralID?: number;
	CoverageCode?: string;
	CoverageName?: string;
	Disclaimer?: boolean;
	Note?: string;
	Token?: string;

	/* POLICY DETAIL - END */

	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderBirthDate?: Date;

	ReferenceNumber?: string;
	PremiumTotalAmount?: string;
	Status?: ENUM_SPPA_STATUS;

	/* POLICYHOLDER - END */


	/* VEHICLE DETAIL - START */

	LicensePlatePrefixID?: number;
	LicensePlatePrefixCode?: string;
	LicensePlatePrefixName?: string;
	LicensePlateInfix?: string;
	LicensePlateSuffix?: string;

	VehiclePrice?: number;
	EquipmentNonStandardPrice?: number;
	EquipmentNonStandardNote?: string;
	VehicleRegionCode?: string;
	VehicleRegionName?: string;
	VehicleManufactureYearCode?: string;
	VehicleManufactureYearName?: string;

	VehicleBrandID?: number;
	VehicleBrandCode?: string;
	VehicleBrandName?: string;
	VehicleModelID?: number;
	VehicleModelCode?: string;
	VehicleModelName?: string;
	VehicleSubModelID?: number;
	VehicleSubModelCode?: string;
	VehicleSubModelName?: string;
	VehicleTypeID?: number;
	VehicleTypeCode?: number;
	VehicleTypeName?: number;
	VehicleColorID?: number;
	VehicleColorCode?: string;
	VehicleColorName?: string;
	VehicleChasisNumber?: string;
	VehicleEngineNumber?: string;
	VehicleFunctionID?: number;
	VehicleFunctionCode?: string;
	VehicleFunctionName?: string;
	VehicleSeatCapacity?: string;
	VehicleLocationID?: number;
	VehicleLocationCode?: string;
	VehicleLocationName?: string;
	VehicleSerie?: string;
	AuthorizedWorkshop?: boolean;
	PostalID?: number;
	PostalCode?: string;
	PostalName?: string;
	CityCode?: string;
	CityName?: string;
	ProvinceCode?: string;
	ProvinceName?: string;

	PartialLossVehicle?: number;
	PersonalAccidentDriver?: number;
	ThirdPartyLiability?: number;

	/* VEHICLE DETAIL - END */

	/* INSURED - START */

	modelProductGeneral?: ProductGeneralModel;
	listModelProductMyMotorcycleGallery?: Array<ProductMyMotorcycleGalleryModel>;
	listModelBeneficiariesMyMotorcycle?: Array<BeneficiariesMyMotorcycleModel>;

	/* Attribute - END */


	//#region CONSTRUCTOR


	//#endregion

	constructor()
	{
		super();
		this.listModelProductMyMotorcycleGallery = [];
		this.listModelBeneficiariesMyMotorcycle = [];
	}

	//#region VALIDATION

	validateAddForUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.listModelProductMyMotorcycleGallery?.length ?? 0 > 0)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	validateUpdateInsuredProduct(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Produk Motorku";

		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERICWITHOUTEMOJI);

		if (this.PartialLossVehicle == null || this.PartialLossVehicle === undefined)
		{
			modelResponse.MessageContent = "Nilai santunan tidak boleh kosong.";
		}

		else if (this.PostalCode == null || this.PostalCode === undefined || this.PostalCode === "")
		{
			modelResponse.MessageContent = "Kode pos tidak boleh kosong.";
		}
		else if (this.PostalCode.length < RuleConstant.NUMBER_POSTALCODE_LENGTH || this.PostalCode.length > RuleConstant.NUMBER_POSTALCODE_LENGTH)
		{
			modelResponse.MessageContent = "Kode pos harus "+RuleConstant.NUMBER_POSTALCODE_LENGTH+" digit.";
		}
		else if (this.PostalName == null || this.PostalName === undefined || this.BuildingPostalName === "")
		{
			modelResponse.MessageContent = "Kode pos tidak ditemukan.";
		}
		else if (!regularExpression.test(this.PostalCode))
		{
			modelResponse.MessageContent = "Format input Kode Pos mengandung karakter yang tidak diizinkan.";
			this.PostalCode = undefined;
		}
		else if (this.VehicleBrandCode == null || this.VehicleBrandName === undefined || this.VehicleBrandName === "")
		{
			modelResponse.MessageContent = "Merek kendaraan tidak boleh kosong.";
		}
		else if (this.VehicleModelName == null || this.VehicleModelName === undefined || this.VehicleModelName === "")
		{
			modelResponse.MessageContent = "Model kendaraan tidak boleh kosong.";
		}
		else if (this.VehicleSubModelName == null || this.VehicleSubModelName === undefined || this.VehicleSubModelName === "")
		{
			modelResponse.MessageContent = "Sub model kendaraan tidak boleh kosong.";
		}
		else if(this.VehicleLocationName == null || this.VehicleLocationName === undefined || this.VehicleLocationName === "")
		{
			modelResponse.MessageContent = "Lokasi kendaraan tidak boleh kosong";
		}

		else if (this.VehicleManufactureYearCode == null || this.VehicleManufactureYearCode === undefined)
		{
			modelResponse.MessageContent = "Tahun kendaraan tidak boleh kosong.";
		}
		else if (!regularExpression.test(this.VehicleManufactureYearCode))
		{
			modelResponse.MessageContent = "Format input Tahun Kendaraan mengandung karakter yang tidak diizinkan.";
			this.VehicleManufactureYearCode = undefined;
		}

		else if (this.LicensePlatePrefixCode == null || this.LicensePlatePrefixCode === undefined || this.LicensePlatePrefixCode === "")
		{
			modelResponse.MessageContent = "Awalan plat kendaraan tidak boleh kosong.";
		}
		else if (!regularExpression.test(this.LicensePlatePrefixCode))
		{
			modelResponse.MessageContent = "Format input Awalan Plat Kendaraan mengandung karakter yang tidak diizinkan.";
			this.LicensePlatePrefixCode = undefined;
		}

		else if (this.LicensePlateInfix == null || this.LicensePlateInfix === undefined || this.LicensePlateInfix === "")
		{
			modelResponse.MessageContent = "Nomor plat kendaraan tidak boleh kosong.";
		}
		else if (!regularExpression.test(this.LicensePlateInfix))
		{
			modelResponse.MessageContent = "Format input Nomor Plat Kendaraan mengandung karakter yang tidak diizinkan.";
			this.LicensePlateInfix = undefined;
		}

		else if (this.LicensePlateSuffix == null || this.LicensePlateSuffix === undefined || this.LicensePlateSuffix === "")
		{
			modelResponse.MessageContent = "Akhiran plat kendaraan tidak boleh kosong.";
		}
		else if (!regularExpression.test(this.LicensePlateSuffix))
		{
			modelResponse.MessageContent = "Format input Akhiran Plat Kendaraan mengandung karakter yang tidak diizinkan.";
			this.LicensePlateSuffix = undefined;
		}

		else if(this.VehicleManufactureYearCode == null || this.VehicleManufactureYearCode === undefined || this.VehicleManufactureYearCode === "")
		{
			modelResponse.MessageContent = "Tahun produksi tidak boleh kosong.";
		}
		else if (regularExpression.test(this.VehicleManufactureYearCode) === false)
		{
			modelResponse.MessageContent = "Format input Tahun Produksi mengandung karakter yang tidak diizinkan.";
			this.VehicleManufactureYearCode = undefined;
		}

		else if(this.VehicleChasisNumber == null || this.VehicleChasisNumber === undefined || this.VehicleChasisNumber === "")
		{
			modelResponse.MessageContent = "Nomor rangka kendaraan tidak boleh kosong.";
		}
		else if(this.VehicleChasisNumber.length > NumberConstant.NUMBER_VALUE_FORM_CHASISNUMBER)
		{
			modelResponse.MessageContent = "Nomor rangka kendaraan tidak boleh lebih dari "+NumberConstant.NUMBER_VALUE_FORM_CHASISNUMBER+".";
		}
		else if (!regularExpression.test(this.VehicleChasisNumber))
		{
			modelResponse.MessageContent = "Format input Nomor Rangka mengandung karakter yang tidak diizinkan.";
			this.VehicleChasisNumber = undefined;
		}

		else if(this.VehicleEngineNumber == null || this.VehicleEngineNumber === undefined || this.VehicleEngineNumber === "")
		{
			modelResponse.MessageContent = "Nomor mesin kendaraan tidak boleh kosong.";
		}
		else if(this.VehicleEngineNumber.length > NumberConstant.NUMBER_VALUE_FORM_ENGINENUMBER)
		{
			modelResponse.MessageContent = "Nomor mesin kendaraan tidak boleh lebih dari "+NumberConstant.NUMBER_VALUE_FORM_ENGINENUMBER+".";
		}
		else if (!regularExpression.test(this.VehicleEngineNumber))
		{
			modelResponse.MessageContent = "Format input Nomor Mesin Kendaraan mengandung karakter yang tidak diizinkan.";
			this.VehicleEngineNumber = undefined;
		}

		else if(this.VehicleColorName == null || this.VehicleColorName === undefined || this.VehicleColorName === "")
		{
			modelResponse.MessageContent = "Warna kendaraan tidak boleh kosong";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
		}

		return modelResponse;
	}

	validateTokenForUpdate(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk Motorku");

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token tidak boleh kosong.";
		}
		else
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk Motorku");

		if(this.listModelProductMyMotorcycleGallery == null || this.listModelProductMyMotorcycleGallery === undefined)
		{
			modelResponse.MessageContent = "Terdapat kesalahan pada daftar lampiran.";
		}
		else
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUploadDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateUpload();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.listModelProductMyMotorcycleGallery?.length ?? 0 > 0)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Dokumen tidak boleh kosong, harap lampirkan file.";
			}
		}
		else
		{
			modelResponse.MessageContent = "Terdapat kesalahan pada daftar lampiran.";
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseProductMyMotorcycleGallery: ResponseModel;
			let modelProductMyMotorcycleGalleryTemp: ProductMyMotorcycleGalleryModel;
			let numberSize: number = 0;

			if(this.listModelProductMyMotorcycleGallery !== undefined)
			{
				for (const modelProductMyMotorcycleGallery of this.listModelProductMyMotorcycleGallery)
				{
					modelProductMyMotorcycleGalleryTemp = new ProductMyMotorcycleGalleryModel();
					modelProductMyMotorcycleGalleryTemp.setModelFromObject(modelProductMyMotorcycleGallery);

					modelResponseProductMyMotorcycleGallery = modelProductMyMotorcycleGallery.validateAdd();

					if (modelResponseProductMyMotorcycleGallery.State === ENUM_RESPONSE_STATE.Fail)
					{
						return modelResponseProductMyMotorcycleGallery;
					}
					else
					{
						numberSize += modelProductMyMotorcycleGallery.Size ?? 0;
					}
				}
			}
			else
			{

			}

			if (numberSize > RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_PRODUCTDAMAGEOFBUSINESS_SUPPORTINGDOCUMENT)
			{
				const pipeConvertByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

				modelResponse.MessageContent = "Maksimal total lampiran adalah " + pipeConvertByteForPreview.transform(RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_PRODUCTDAMAGEOFBUSINESS_SUPPORTINGDOCUMENT);
			}
			else
			{
				modelResponse.MessageContent = "Formulir diisi dengan benar.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		if(modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			let numberDocumentSuccess: number = 0;
			let numberDocumentFailed: number = 0;
			let booleanBreak: boolean = false;

			const arrayModelSupportingDocument: Array<SupportingDocumentModel> = this.setSupportingDocument();

			arrayModelSupportingDocument.forEach(modelSupportingDocument =>
			{
				const arrayDocument = this.listModelProductMyMotorcycleGallery?.filter(modelProductProductMyMotorcycleGallery => modelProductProductMyMotorcycleGallery.Type === modelSupportingDocument.Type);

				if(arrayDocument?.length !== 0)
				{
					numberDocumentSuccess = numberDocumentSuccess + 1;
				}
				else
				{
					if(booleanBreak === false)
					{
						booleanBreak = true;
						modelResponse.MessageContent = "Dokumen "+ modelSupportingDocument.Name +" tidak boleh kosong.";
						numberDocumentFailed = numberDocumentFailed + 1;
					}
				}

			});

			if(numberDocumentSuccess === arrayModelSupportingDocument.length)
			{
				modelResponse.MessageContent = "Formulir diisi dengan benar.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
		}

		return modelResponse;
	}

	//#endregion


	//#region SETTER

	setCoverageCodeYearly(): void
	{
		this.CoverageCode = StringConstant.STRING_VALUE_PACKAGE_YEARLY_MYMOTORCYCLE;
		this.Comprehensive = false;
		this.DriverPersonalAccident = false;
		this.EarthquakesTsunamisVolcanicEruptions = false;
		this.FloodTyphoonHurricaneHailLandslide = false;
		this.PassengerPersonalAccident = false;
		this.Riots = false;
		this.TerrorismAndSabotage = false;
		this.ThirdPartyLegal = false;
		this.ThirdPartyLegalValue = 0;
		this.TotalLossOnly = false;
		this.listModelBeneficiariesMyMotorcycle = undefined;
		this.listModelProductMyMotorcycleGallery = undefined;
	}

	setSupportingDocument(): Array<SupportingDocumentModel>
	{
		const arrayModelGalleryModel: Array<SupportingDocumentModel> =
		[
			{
				Type: 0,
				Name : "Foto Kendaraan"
			},
		];

		return arrayModelGalleryModel;
	}

	// setForUpdateInsuredDamageOfBusinessPlaceForm(modelProductDamageOfBusinessPlace: ProductDamageOfBusinessPlaceModel): void
	// {
	// 	this.BuildingAddress = modelProductDamageOfBusinessPlace.BuildingAddress;
	// 	this.BuildingPostalCode = modelProductDamageOfBusinessPlace.BuildingPostalCode;
	// 	this.LocationInformation = modelProductDamageOfBusinessPlace.LocationInformation;
	// 	this.OwnershipInformation = modelProductDamageOfBusinessPlace.OwnershipInformation;
	// 	this.SumInsuredDefault = modelProductDamageOfBusinessPlace.SumInsuredDefault;
	// 	this.Token = modelProductDamageOfBusinessPlace.Token;

	// 	if(modelProductDamageOfBusinessPlace.listModelBeneficiariesDamageOfBusinessPlace != null || modelProductDamageOfBusinessPlace.listModelBeneficiariesDamageOfBusinessPlace !== undefined)
	// 	{
	// 		modelProductDamageOfBusinessPlace.listModelBeneficiariesDamageOfBusinessPlace.forEach(modelBeneficiariesDamageOfBusinessPlace =>
	// 		{
	// 			this.listModelBeneficiariesDamageOfBusinessPlace?.push(modelBeneficiariesDamageOfBusinessPlace);
	// 		});
	// 	}
	// }

	//#endregion


	//#region SETTER

	setMyHouseYearly(): void
	{
		this.CoverageCode = StringConstant.STRING_VALUE_PACKAGE_YEARLY_MYHOUSE;
	}

	setProgressforDraf(): number
	{
		let numberProgress: number = 0;

		if(this.listModelProductMyMotorcycleGallery == null || this.listModelProductMyMotorcycleGallery === undefined || this.listModelProductMyMotorcycleGallery.length === 0)
		{
			numberProgress = 20;
		}
		else if(this.listModelBeneficiariesMyMotorcycle == null || this.listModelBeneficiariesMyMotorcycle === undefined || this.listModelBeneficiariesMyMotorcycle.length === 0)
		{
			numberProgress = 50;
		}
		else
		{
			numberProgress = 80;
		}

		return numberProgress;
	}

	//#endregion


	//#region CLEAN

	cleanVehicleModel(): void
	{
		this.VehicleModelCode = undefined;
		this.VehicleModelName = undefined;
	}

	cleanVehicleBrand(): void
	{
		this.VehicleBrandCode = undefined;
		this.VehicleBrandName = undefined;
	}

	//#endregion


	//#region GENERATE

	generateManufactureYear(numberManufactureYearCoverage: number): Array<number>
	{
		// deepcode ignore ArrayConstructor: <please specify a reason of ignoring this>
		const arrayNumberManufactureYear: Array<number> = new Array<number>();
		const numberYear: number = new Date().getFullYear();
		const numberMinimunYear: number = numberYear - numberManufactureYearCoverage;

		for (let numberYearCalc: number = numberYear; numberYearCalc >= numberMinimunYear; numberYearCalc--)
		{
			arrayNumberManufactureYear.push(numberYearCalc);
		}

		return arrayNumberManufactureYear;
	}

	//#endregion

}
//#endregion